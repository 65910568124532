/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../common/constants/APIEndpoints";
import { getAuthConfig } from "../../common/functions/getAuthToken";
import { subtractDate } from "../../common/functions/subtractDate";
import UITable from "../../common/table/UITable";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./pg.css";

const config = getAuthConfig();
function IndividualStreamer() {
  const [data, setData] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [validDays, setValidDays] = useState([]);
  const [invalidDays, setInvalidDays] = useState([]);
  const [streamInformation, setStreamInformation] = useState();
  const [filterData, setFilterData] = useState({
    from: subtractDate("month"),
    to: moment().format("YYYY-MM-DDTHH:MM"),
  });

  let { id } = useParams();

  const getStreamerData = (from, to) => {
    axios
      .get(
        `${BASE_URL}/api/admin/getStreamerDetailedData/${id}?from=${moment(
          from
        ).format("YYYY-MM-DD")}&to=${moment(to).format("YYYY-MM-DD")}`,
        config
      )
      .then((res) => setData(res.data));
  };

  const getStreamInformation = async () => {
    axios
      .get(
        `${BASE_URL}/api/admin/getIndividualStreamerDataByDate/${id}/${moment(
          selectedDate
        ).toISOString()}`,
        config
      )
      .then((res) => setStreamInformation(res.data));
  };

  const getStreamerValidInvalidDays = async (dateParam) => {
    axios
      .get(
        `${BASE_URL}/api/admin/getStreamerMonthValidInvalidDays/${id}/${moment(
          dateParam
        ).toISOString()}`,
        config
      )
      .then((response) => {
        const res = response.data;
        if (res.success === false) return;

        setValidDays(res.validDays);
        setInvalidDays(res.invalidDays);
      });
  };

  useEffect(() => {
    getStreamerValidInvalidDays(selectedDate);
  }, []);

  useEffect(() => {
    getStreamInformation();
  }, [selectedDate]);

  useEffect(() => {
    const urlSplit = window.location.href.split("?");
    if (!urlSplit || urlSplit.length <= 1) {
      getStreamerData(filterData.from, filterData.to);
      return;
    }

    const params = urlSplit[1].split("&");

    if (!params || params.length <= 1) return;

    const from = params[0].split("from=")[1];
    const to = params[1].split("to=")[1];

    const fromPayload = moment(from, "YYYY-MM-DD").format("YYYY-MM-DDTHH:MM");
    const toPayload = moment(to, "YYYY-MM-DD").format("YYYY-MM-DDTHH:MM");
    setFilterData({
      from: fromPayload,
      to: toPayload,
    });
    getStreamerData(fromPayload, toPayload);
  }, [window.location.href]);

  let columns = [
    {
      field: "id",
      hide: false,
      headerName: "ID",
      width: 100,
    },
    {
      field: "viewersCount",
      hide: false,
      headerName: "Total viewers",
      width: 100,
    },
    {
      field: "timeband",
      hide: false,
      headerName: "Time Band",
      width: 100,
    },
    {
      field: "duration",
      hide: false,
      headerName: "Stream time (mins)",
      width: 150,
      valueFormatter: (params) => Math.round(params.value / 60),
    },
    {
      field: "",
      hide: false,
      headerName: "Stream time (hrs)",
      width: 150,
      valueGetter: (params) => {
        const hours = Math.floor(params.row.duration / 3600);
        const minutesInDuration = Math.round(params.row.duration / 60);
        const minutesModulus = Math.round(minutesInDuration % 60);
        const result = hours + "h " + minutesModulus + "m";
        return result;
      },
    },
    {
      field: "createdAt",
      hide: false,
      headerName: "Stream Created",
      type: "dateTime",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "startTime",
      hide: false,
      headerName: "Stream Started",
      type: "dateTime",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "endTime",
      hide: false,
      headerName: "Stream End",
      type: "dateTime",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "viewers",
      hide: false,
      headerName: "Total Viewers",
      width: 100,
      valueGetter: (params) => {
        const viewers = params.row.viewers ?? 0;
        const new_viewers = params.row.new_viewers ?? 0;
        return viewers + new_viewers;
      },
    },
    {
      field: "new_viewers",
      hide: false,
      headerName: "New Viewers",
      width: 100,
      valueFormatter: (params) => params?.value ?? 0,
    },
  ];

  let rows = data?.stream_data || [];

  const getStreamTimeHours = (streamTime) => {
    if (!streamTime) return "0h 0m";

    const hours = Math.floor(streamTime / 60);
    const minutes = Math.round(streamTime % 60);
    const result = hours + "h " + minutes + "m";
    return result;
  };

  const streamInformationHeader = useMemo(
    () => (
      <>
        <div style={{ width: "100%", display: "flex" }}>
          <p className="stream-information-header">Title</p>
          <p
            style={{
              width: "30%",
              height: "100%",
              border: "solid 1px gray",
              margin: "0px",
              padding: "10px",
              fontWeight: "bold",
            }}
          >
            Duration (mins)
          </p>
          <p
            style={{
              width: "30%",
              height: "100%",
              border: "solid 1px gray",
              margin: "0px",
              padding: "10px",
              fontWeight: "bold",
            }}
          >
            Viewers
          </p>
        </div>
      </>
    ),
    []
  );

  const streamInformationData = useMemo(
    () => (
      <>
        {" "}
        {streamInformation && streamInformation.length > 0 ? (
          streamInformation?.map((streamInfo, index) => (
            <div key={`stream-information-${index}`}>
              <div style={{ width: "100%", display: "flex" }}>
                <p
                  style={{
                    width: "40%",
                    height: "100%",
                    border: "solid 1px gray",
                    margin: "0px",
                    padding: "10px",
                  }}
                >
                  {streamInfo.title}
                </p>
                <p
                  style={{
                    width: "30%",
                    height: "100%",
                    border: "solid 1px gray",
                    margin: "0px",
                    padding: "10px",
                  }}
                >
                  {(streamInfo.duration / 60).toFixed(2)}
                </p>
                <p
                  style={{
                    width: "30%",
                    height: "100%",
                    border: "solid 1px gray",
                    margin: "0px",
                    padding: "10px",
                  }}
                >
                  {streamInfo.viewers}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="no-stream-information-message">
            <p>
              No stream information to display for{" "}
              <strong>{moment(selectedDate).format("MM/DD/YYYY")}</strong>
            </p>
          </div>
        )}
      </>
    ),
    [streamInformation]
  );

  return (
    <div>
      <div style={{ marginTop: "30px" }}>
        <label style={{ fontSize: "14px", fontWeight: "bold" }}>Date</label>
        <div style={{ marginTop: "3px" }}>
          <DatePicker
            showIcon
            selected={selectedDate}
            onChange={(date) => {
              if (selectedDate.getDate() === date.getDate()) return;
              setSelectedDate(date);
            }}
            onMonthChange={(monthDate) =>
              getStreamerValidInvalidDays(monthDate)
            }
            highlightDates={[
              {
                "calendar-valid-dates": validDays.map(
                  (validDay) => new Date(validDay)
                ),
              },
              {
                "calendar-invalid-dates": invalidDays.map(
                  (invalidDay) => new Date(invalidDay)
                ),
              },
            ]}
          />
        </div>
        <div style={{ marginTop: "10px" }}>
          {streamInformationHeader}
          {streamInformationData}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "30px 0px 40px 0px",
        }}
      >
        <div style={{ paddingRight: "20px" }}>
          <p>From</p>
          <input
            type="datetime-local"
            id="birthdaytime"
            name="birthdaytime"
            value={filterData.from}
            onChange={(e) =>
              setFilterData({ ...filterData, from: e.target.value })
            }
          />
        </div>
        <div style={{ paddingRight: "20px" }}>
          <p>To</p>
          <input
            type="datetime-local"
            id="birthdaytime"
            name="birthdaytime"
            value={filterData.to}
            onChange={(e) =>
              setFilterData({ ...filterData, to: e.target.value })
            }
          />
        </div>
        <p>or</p>
        <div style={{ padding: "0px 20px" }}>
          <p>Select timeline:</p>
          <select
            name="timeline"
            id="timeline"
            defaultValue="month"
            onChange={(e) => {
              setFilterData({
                ...filterData,
                from: subtractDate(e.target.value),
                to: moment().format("YYYY-MM-DDTHH:mm:ss"),
              });
            }}
          >
            <option value="day">Last 24hrs</option>
            <option value="week">Last week</option>
            <option value="month">Last month</option>
            <option value="year">Last year</option>
          </select>
        </div>
        <button
          className="normal-button"
          style={{ marginLeft: "10px" }}
          onClick={() => getStreamerData(filterData.from, filterData.to)}
        >
          Apply Filter
        </button>
      </div>
      <hr />
      <div style={{ display: "flex", textAlign: "left", margin: "50px 0px" }}>
        <img src={data?.streamerDetails?.avatar} width="128px" height="128px" />
        <div>
          <p>
            ID: <b>{data?.streamerDetails?._id}</b>
          </p>
          <p>
            Name: <b>{data?.streamerDetails?.name}</b>
          </p>
          <p>
            Username: <b>{data?.streamerDetails?.username}</b>
          </p>
          <p>
            Email: <b>{data?.streamerDetails?.email}</b>
          </p>
          <br />
          <p>
            Total Number of Days: <b>{data?.totalNumOfDays}</b>
          </p>
          <p>
            Number of days streamed: <b>{data?.numOfDaysStreamed}</b>
          </p>
          <p>
            Valid Days: <b>{data?.validDays}</b>
          </p>
          <p>
            Invalid Days: <b>{data?.invalidDays}</b>
          </p>
          <br />
          <p>
            Number of streams: <b>{data?.numOfStreams}</b>
          </p>
          <p>
            Total stream time: <b>{Math.round(data?.totalStreamTime)} mins</b>
          </p>
          <p>
            Total stream time in hours:{" "}
            <b>{getStreamTimeHours(data?.totalStreamTime)}</b>
          </p>
          <p>
            Average watch time:{" "}
            <b>{Math.floor(data?.avg_watchtime / 60)} mins</b>
          </p>
          <br />
          <p>
            Total Number of Viewers: <b>{data?.numOfViewers}</b>
          </p>
          <p>
            New Viewers: <b>{data?.newViewers}</b>
          </p>
        </div>
      </div>
      <hr />
      <div>
        <p
          style={{
            fontSize: "32px",
            textAlign: "left",
            margin: "40px 0 10px 5px",
          }}
        >
          Streams:
        </p>
        <UITable columns={columns} rows={rows} />
      </div>
    </div>
  );
}

export default IndividualStreamer;
